:root {
    --color-background: #ffffff;
    --color-primary: #6c757d;
    --color-secondary: #dcd6d394;
    --color-text: #212529;
    --color-red: #ff0000;
    /* Definir un color rojo para mayor reutilización */
    --transition-speed: 0.3s;
    --menu-collapsed-width: 60px;
    --menu-expanded-width: 248px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.layout {
    display: flex;
}

.side-menu {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: var(--menu-collapsed-width);
    overflow: hidden;
    background-color: var(--color-background);
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.3);
    transition: width var(--transition-speed) ease;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 3;
}

.side-menu:hover {
    width: var(--menu-expanded-width);
}

.menu-content {
    width: var(--menu-expanded-width);
    display: flex;
    flex-direction: column;
}

.icon-container {
    display: flex;
    padding: 20px 18px;
    position: relative;
    transition: padding var(--transition-speed), margin var(--transition-speed);
}
.menu-item.disabled {
    visibility: hidden;
    pointer-events: none; /* Deshabilita la interacción */

  cursor: not-allowed; /* Cambia el cursor */
  }
  
  .menu-item:not(.disabled) {
    visibility: visible;
    pointer-events: auto; /* Permite la interacción */
  }
  
.home-icon {
    width: 28px;
    height: 28px;
    color: var(--color-primary);
    transition: margin-left var(--transition-speed) ease;
}

.side-menu:hover .home-icon {
    margin-left: calc((var(--menu-expanded-width) - 60px) / 2);
}

.logout-container {
    position: absolute;
    bottom: 0px;
    height: 2.5rem;
    width: calc(var(--menu-expanded-width));

    transition: background-color var(--transition-speed);
}

.logout-container .item-text {
    position: inherit;
    margin-top: 8px;
}

.logout-container:hover {
    background-color: var(--color-secondary);
}

/* Cambiar color del ícono de "Cerrar Sesión" */
.logout-container .item-icon {
    margin-top: 8px;
    color: var(--color-red);
    /* Usar variable para el color rojo */
}

.menu-items {
    margin-left: 0;
    cursor: pointer;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    color: var(--color-text);
    text-decoration: none;
    transition: background-color var(--transition-speed);
    white-space: nowrap;
}



.menu-item:hover {
    background-color: var(--color-secondary);
}

.item-icon {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    /* Usar margen uniforme */
    color: var(--color-primary);
}

.item-text {
    opacity: 0;
    transition: opacity var(--transition-speed);
}

.notification-icon .item-text {
    opacity: 0;
    transition: opacity var(--transition-speed);
    margin-left: 10px; /* Añadir esta línea para dar margen al texto de notificación */
}

.side-menu:hover .item-text {
    opacity: 1;
}

.main-content {
    flex-grow: 1;
    margin-left: var(--menu-collapsed-width);
    padding: 20px;
    padding-top: 15px;
    transition: margin-left var(--transition-speed) ease;
}

.submenu {
    padding-left: 20px;
    cursor: pointer;
}

.submenu-item {
    display: flex;
    padding: 10px 30px;
    color: #666;
    cursor: pointer;
    text-decoration: none;
}

.submenu-item:hover {
    background-color: var(--color-secondary);
    border-radius: 20px;
}

.chevron {
    margin-left: auto;
    transition: transform 0.2s;
}

.chevron.open {
    transform: rotate(180deg);
}


.custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    color: var(--color-text);
    text-decoration: none;
    transition: background-color var(--transition-speed), box-shadow var(--transition-speed), width var(--transition-speed), height var(--transition-speed);
    /* Asegúrate de incluir el tamaño aquí */
    white-space: nowrap;
    cursor: pointer;
    background-color: #dcd6d394;
    /* Color gris */
    border: none;
    margin-left: 7px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Al colapsar */
.custom-button.collapsed {
    width: 48px;
    height: 50px;
    padding: 0;
    justify-content: center;
    transition: background-color var(--transition-speed), box-shadow var(--transition-speed), width 3s ease, height 3s ease;
    /* Aumenta la duración aquí */
}

/* Efecto hover */
.custom-button:hover {
    background-color: var(--color-secondary);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}


.item-icon.collapsed {
    display: block;
    /* Asegúrate de que el ícono se muestre */
    width: 24px;
    /* Ajusta el tamaño del ícono para que sea visible */
    height: 24px;
    /* Asegúrate de que sea cuadrado */
    margin-right: 9px;
    /* Eliminar margen a la derecha para que no se desplace */
}



.custom-button:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Sombra al hacer clic */
    transform: translateY(2px);
    /* Efecto de pulsación */
}
.home-link {
    text-decoration: none; /* Elimina el subrayado */
    color: inherit; /* Mantén el color del texto heredado del contenedor */
  }
  
  .home-link:hover {
    text-decoration: none; /* Asegúrate de que tampoco se subraye al pasar el cursor */
  }