.button-container {
    display: flex;
    gap: 8px;
    
    justify-content: center;
    margin: auto;
    margin-top: 28px;
  }
  
  .tramitar-button {
    
    padding: 5px 10px;
    font-size: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 9px;
  }
  
  .tramitar-button.red{
    background-color: #f35f58;
  }
  .tramitar-button.red:hover{
    background-color: #c82333;
  }
  .tramitar-button.green{
    background-color: #00CBA9;
  }
  .tramitar-button.green:hover{
    background-color: rgb(65, 171, 65);
  }
  .semaforo {
    display: flex;
    border-radius: 9px;
    padding: 5px;
    justify-content: space-around;
    height: 20px;
    margin: auto;
    width: 40px;
    background-color: #757575;
  }
  
  .semaforo-circulo {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  
  .semaforo-circulo.verde {
    background-color: rgb(0, 255, 0);
  }
  
  .semaforo-circulo.amarillo {
    background-color: yellow;
  }
  
  .semaforo-circulo.rojo {
    background-color: red;
  }

  .row-update {
    animation: highlightRow 1s ease-in-out;
  }
  
  @keyframes highlightRow {
    0% {
      background-color: rgba(0, 123, 255, 0.1);
    }
    100% {
      background-color: transparent;
    }
  }
  
  .dirigir-updated {
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 255, 0, 0.1);
  }

  .row-fade-out {
    animation: fadeOut 0.5s ease-out forwards;
    pointer-events: none;
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
      height: 0;
      margin: 0;
      padding: 0;
    }
  }
