.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8); /* Fondo blanco con 80% de opacidad */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Asegúrate de que esté por encima de otros elementos */
  }
  
  .loading-container {
    background: rgb(255, 255, 255); /* Fondo blanco con 20% de opacidad */
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Sombra suave */
  }
  
  .loader {
    width: 100px; /* Ajusta el tamaño según sea necesario */
    height: 100px; /* Ajusta el tamaño según sea necesario */
    margin-bottom: 10px; /* Espaciado inferior */
  }
  