/* Variables CSS */
:root {
    --color-grey: rgba(128, 128, 128, 0.608);
    --color-white: #ffffff;
    --color-black: rgb(0, 0, 0);
    --color-green: #10b981;
    --color-red: #ef4444;
    --color-blue: #3B82F6;
    --color-dark-blue: #4b5563;
    --shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    --border-radius: 50px;
    --button-radius: 15px;
}

/* Container Principal */
.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.container.visible {
    opacity: 1;
}

.tramite-factura-container {
    background-color: var(--color-white);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    padding: 20px;
    height: 30rem;
    width: 70%;
    text-align: center;
}

.tramite-causacion-container {
    background-color: var(--color-white);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    padding: 20px;
    height: 30rem;
    width: 90%;
    text-align: center;
}


.cont-factura {
    position: absolute;
    width: 67%;
    height: 60%;

    overflow-y: auto;
}

.cont-factura-causacion {
    position: absolute;
    width: 87%;
    height: 60%;

    overflow-y: auto;
}

.cont-factura-2 {
    display: grid;
    background-color: var(--color-white);
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: 97%;
    gap: .7rem;
}

.cont-causar-1 {
    display: grid;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e0e0e0;
    gap: .7rem;
}

.grid-causar {
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px dashed #e0e0e0;
}

.form-header-alerts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -.6rem 2rem .2rem 40%;
}

.notificationss-1 {
    width: 35%;
    /* Ajustado para que esté alineado correctamente */
    height: auto;
    order: 2;
    /* Se mantiene a la derecha */
    max-width: 400px;
    flex: 1 1 auto;
    align-self: flex-start;
    /* Alineado arriba */
}

.disabled-label {
    opacity: 0.5;
    /* Reduce la opacidad */
    pointer-events: none;
    /* Evita interacción adicional */
}

/* Cuadro de Aprobación */
.approval-box {
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    padding: 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 256px;
}

/* Estilo para la sesión activa */
.session-active {
    background-color: var(--color-white);
    /* Color de fondo blanco */
    padding: 30px;
    /* Dimensiones específicas */
    width: 480px;
    /* Ancho específico */
}

/* Íconos */
.check-icon,
.error-icon,
.session-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
}

.check-icon {
    color: var(--color-green);
}

.error-icon {
    color: var(--color-red);
}

.session-icon {
    color: var(--color-blue);
}

/* Texto */
.approval-text,
.session-text,
.error-text {
    color: var(--color-dark-blue);
    font-weight: 500;
    font-size: 18px;
}

/* Contenedor de Botones */
.button-container {
    display: flex;
    justify-content: space-around;
    margin-top: 16px;
    width: 100%;
}

.button-container-tablereact {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.button-container-tablereact2 {
    display: grid;
    gap: 5px;
    width: 100%;
}

.button-container-tramite {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 44%;
    width: 90%;
    gap: 2rem;
}

.bntncontainer-causacion {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 34%;
    width: 80%;
    gap: 2rem;
}

.bntncontainer-impuestos-gen {
    all: unset;
    /* Resetea todas las propiedades heredadas */

    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    gap: 2rem;
}



/* Botones de Alerta */
.alert-button {
    font-size: 14px;
    /* width: 195px; */
    width: 50%;
    color: var(--color-black);
    border: none;
    border-radius: var(--button-radius);
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    transition: transform 0.1s ease;
}

.alert-button:active {
    transform: scale(0.95);
}

.alert-button.red {
    /* background-color: #ff1f0f; */
    /* propuesta johan gil */
    background-color: #f35f58;
    /* propuesta Mafe */
}

.alert-button.red:hover {
    background-color: #c82333;
}

.alert-button.blue {
    /* background-color: #ff1f0f; */
    /* propuesta johan gil */
    background-color: #82b7f7;
    /* propuesta Mafe */
}

.alert-button.blue:hover {
    background-color: #0965a2;
}

.alert-button.green {
    /* background-color: #10f445; */
    /* propuesta johan gil */
    background-color: #95dd8b;
    /* Propuesta Mafe */
}

.alert-button.green:hover {
    background-color: #218838;
}

.icon {
    margin-right: 8px;
}


/* Estilos para FormVerify */
.container-verify {
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 34rem;
    width: 25rem;
}

.form-data {
    overflow: auto;
    width: 100%;
}

.approval-text-ver {
    margin: .3rem;
    font-size: 1.3em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.form-data p {
    margin: 8px 0;
    font-size: 1em;
    color: #555;
}

.form-data p strong {
    font-weight: 600;
    color: #333;
}

.section-title-ver {
    font-size: 1.2em;
    font-weight: bold;
    color: #007bff;
    margin-top: 20px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
}

/* Estilos para los botones de acción */
.button-container-veri {
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    margin-top: 20px;
}


.edit-button {
    background-color: #26d234 !important;
    border-radius: 20px !important;
    gap: .4rem;
    width: 8rem;
}


.edit-button:hover {
    background-color: #22c946 !important;
}


/* Estilo general del contenedor principal */
.container-welcomegen {
    display: flex;
    justify-content: center;
    /* Centra los elementos hijos */
    align-items: center;

    /* Tamaño completo de la pantalla */
    width: 89.6vw;
    max-height: 100vh;

    /* Evitar que se desborde */
    overflow: hidden;
}


.container-welcomegen>div {
    flex: 1 1 300px;
    /* Se expanden, pero tienen un ancho mínimo */
}

.container-welcome {
    /* 
    background-color: #007bff; */
    display: grid;
    padding-top: 2px;
    padding-left: 3px;
    grid-template-columns: 1.83fr 1fr;
    /* Dos columnas */
    grid-template-rows: 33% 60%;
    /* Filas automáticas */
    gap: 10px;
    /* Espaciado entre elementos */

    /* Tamaño máximo para no desbordarse */
    max-width: 90vw;
    max-height: 91vh;

    /* Asegurar que no desborde */
    overflow: hidden;
    overflow-y: auto;
}


.container-welcome::-webkit-scrollbar {
    display: none;
    /* Oculta la barra de scroll en navegadores WebKit (Chrome, Safari) */
}

.container-welcome2 {
    background-color: white;
    border-radius: 15px;
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.4);
    grid-column: 1 / span 1;
    /* Primera columna */
    grid-row: 1 / span 1;
    /* Primera fila */
    width: 100%;
    height: 100%;
}

.notificationss-1 {
    grid-column: 2 / span 1;
    /* Segunda columna */
    grid-row: 1 / span 2;
    /* Ocupa dos filas */
    width: 100%;
    height: 100%;
    text-align: end;
}

.graficaest {
    background-color: white;
    border-radius: 15px;
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.4);
    grid-column: 1 / span 1;
    /* Primera columna */
    grid-row: 2 / span 1;
    /* Segunda fila */
    width: 100%;
    height: 100%;
}
.torta{
    margin-top: 5%;
}

.prueba2 {
   /*  background-color: #00ff1a; */
    grid-column: 1 / span 2;
    /* Ocupa ambas columnas */
    grid-row: 3 / span 1;
    /* Tercera fila */
    width: 100%;
    height: 50rem;
}


.container-welcome2>.three-column-grid {
    align-items: center;
    gap: 0;
}

/* Contenedor para el estado de carga */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Contenedor para el mensaje de error */
.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* Contenedor de centros de costos para mejor organización */
.centros-costos-container {
    height: 10rem;
    max-height: 300px;
    margin-top: 5px;
    margin-right: 5px;
    /* Limitar la altura para scroll */
    overflow-y: auto;
    /* Habilitar desplazamiento vertical */
    padding: 0.5rem;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Estilo para cada centro de costos */
.centro-costos {
    padding: 1rem;
    margin-top: .5rem;
    margin-bottom: 1rem;
    /* Mayor separación entre centros */
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* Sombra más visible */
    transition: transform 0.2s, box-shadow 0.2s;
    /* Animación para hover */
}

.red-text {
    background-color: #f35f58;
}

.centro-costos:hover {
    transform: scale(1.02);
    /* Leve ampliación */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    /* Sombra más fuerte */
}



/* Estilos Responsivos */
@media only screen and (max-width: 390px) {
    .approval-box {
        padding: 20px;
        width: 350px;
    }

    .button-container {
        flex-direction: column;
        align-items: center;
    }

    .alert-button {
        font-size: 12px;
        margin-bottom: 8px;
    }
}

@media (min-width: 630px) and (max-width: 768px) {

    .button-container-tramite {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: 90%;
        width: 100%;
        gap: 2%;
    }

    .cont-factura {
        position: absolute;
        width: 64%;
        height: 59%;

        overflow-y: auto;
    }

    .cont-factura-2 {
        gap: .3rem;
        width: 95%;
    }

    .form-header-alerts {
        margin: -.6rem .5rem .2rem 30%;
    }

    .bntncontainer-causacion {
        margin-top: 74%;
    }

    .cont-factura-causacion {
        width: 85%;
        height: 65%;

    }

    .container-welcome2 {
        background-color: var(--color-white);
        border-radius: var(--border-radius);
        box-shadow: var(--shadow);
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        min-height: 14rem;
        max-width: 25rem;


    }
}

@media (min-width: 950px) and (max-width: 1199px) {
    .button-container-tramite {

        margin-top: 59%;
    }

    .cont-factura-2>.three-column-grid {
        gap: 1rem;
    }

    .tramite-causacion-container {
        height: 80%;
    }

    .cont-factura-causacion {
        height: 67%;

    }

    .bntncontainer-causacion {
        margin-top: 80%;
    }
}

@media (min-width: 1500px) {
    .button-container-tramite {

        margin-top: 37%;
    }

    .button-container-tramite-causacion {
        margin-top: 7%;
    }


    .cont-factura {
        height: 60%;

    }

    .cont-factura-2 {
        gap: .3rem;
        height: auto;
    }

    .tramite-causacion-container {
        height: 77%;
    }

    .cont-factura-causacion {
        height: 62%;

    }

    .bntncontainer-causacion {
        margin-top: 49%;
    }
}

@media (min-width: 1820px) {
    .button-container-tramite {

        margin-top: 29%;
    }

    .cont-factura {
        position: absolute;
        height: 39%;

    }

    .cont-factura-2 {
        gap: .3rem;
        height: auto;
    }

    .tramite-causacion-container {
        height: 80%;
    }

    .cont-factura-causacion {
        height: 67%;

    }

    .bntncontainer-causacion {
        margin-top: 39%;
    }
    .container-welcomegen {
        width: 95vw;
        max-height: 100vh;
    }
    .container-welcome {
        grid-template-columns: 3.3fr 1fr;
        grid-template-rows: 20% 60%;
        gap: 20px;
    
        /* Tamaño máximo para no desbordarse */
        max-width: 90vw;
        max-height: 93.6vh;
    
    }
    .container-welcome2 {
        /* Primera fila */
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 80%;
        height: 100%;
    }

}