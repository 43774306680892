.containerGeneral {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 93.2vh;
    position: relative;
    z-index: 1.5;
}

.containerframe1 {
    background-color: #ffffff;
    border-radius: 59px;
    padding: 44px 60px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
    max-width: 500px;
    width: 100%;
    z-index: 1;
    margin-right: 4%; /* Añadido margen en la parte inferior */
}

.containerframenotification {
    background-color: #ffffff;
    border-radius: 59px;
    padding: 44px 60px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
    max-width: 500px;
    width: 100%;
    max-height: 400px; /* Mantén el límite de altura */
    height: 100%; /* Asegúrate de que tome el 100% de la altura disponible */
    overflow-y: scroll; /* Permite el desplazamiento si el contenido se desborda */
    z-index: 1;

    /* Ocultar el scrollbar */
    scrollbar-width: none; /* Oculta el scrollbar en Firefox */
}

.containerframenotification::-webkit-scrollbar {
    display: none; /* Oculta el scrollbar en navegadores basados en WebKit */
}

.containerframe2 {
    background-color: #ffffff;
    border-radius: 59px;
    padding: 44px 60px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
    max-width: 900px;
    height: 530px;
    width: 100%;
    z-index: 1;
}

.titless {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 42px;
    color: #333;
}

.buttoncontainerempresa {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.companybuttonempresa {
    width: 100%;
    /* background-color: #9adde6dd; *//* propuesta johan gil */
    background-color: #0098FF;/* propuesta Mafe */
    color: white;
    font-size: 19px;
    font-weight: 680;
    padding: 18px 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 1.5px 2px 4px rgba(0, 0, 0, 0.5);
}

.companybuttonempresa:hover {
    background-color: #ccefff9b;
    transform: scale(1.05);
}

.companybuttonempresa:active {
    transform: scale(0.95); /* Efecto de presionar */
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3); /* Sombra más suave */
}




