/* Estilos para el contenedor principal de la tabla */
.table-container {
    height: 185px;
    width: 100%;
    border-radius: 10px !important; 
    overflow: hidden;
    box-shadow: 4px 2px 2px rgba(0, 0, 0, 0.3) !important;
}

/* Estilos para el encabezado de la tabla */
.MuiTableCell-head {
    background-color: #61c4ff !important; /* Cambia el color del fondo del encabezado */
    font-weight: bold !important; /* Negrita para el encabezado */
    padding: 18px !important; /* Ajusta el espaciado */
    text-align: center !important;
    

    
    word-wrap: break-word !important; /* Rompe palabras largas */
    white-space: normal !important; /* Permite que el texto haga salto de línea */
    font-size: 12px !important; /* Ajusta el tamaño de fuente si es necesario */
    line-height: 1.2 !important; /* Espaciado entre líneas */
}

/* Estilo para el elemento Table */
.table {
    table-layout: fixed;
}


.attach-button {
    background-color: #00CBA9;
    color: white;
    border: none;
    padding: 5px 10px;
    height: 2rem;
    width: 10rem;
    cursor: pointer;
    font-size: 14px;
    border-radius: 8px;
    box-shadow: 4px 2px 2px rgba(0, 0, 0, 0.3);
  }
  
  .attach-button:hover {
    background-color: #45a049;
  }
  

  /* src/common/ReactVirtualizedTable.css */

.table-cell {
    background-color: white;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis  !important;



    overflow-wrap: anywhere !important; /* Permite que las palabras largas se ajusten */
 
}
/* src/common/ReactVirtualizedTable.css */

.link-cell {
    max-width: auto; /* Ajusta según el ancho deseado */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal;
}


.table-container .table-cell.actions-column {
    width: 50px !important; /* Ajusta este valor según sea necesario */
    text-align: center; /* Alinea el contenido al centro */
    padding: 0; /* Opcional: reduce el espacio interno */
  }





/* Centrar horizontal y verticalmente el contenido de las celdas */
.MuiTableCell-root {
    text-align: center !important; /* Centrar horizontalmente */
}

