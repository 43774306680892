/* Header */
.form-header-ordencompra {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .8rem 2rem 1rem 9%;
}

.form-title-factinternas {
    font-size: 1.6rem;
    font-weight: 600;
    color: #2d3748;
    margin-right: 8rem;
    margin-top: .6rem;
}

.form-title-ordencompra {
    font-size: 1.6rem;
    font-weight: 600;
    color: #2d3748;
    margin-right: 8rem;
}
.p-align-izq{
margin-left: -3.5rem;
}


.form-date-basic---orden {
    width: 108%;
    margin-left: -4.8%;
    /* border-top: 1px solid #b6bbc2; */
    /* propuesta johan gil */
    height: 27rem;
    border-bottom: 1px solid #b6bbc2;
    overflow-y: auto;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

/* Formulario Persona Natural */
.orden-date1-section {
    position: relative;
    display: grid;
    height: 9rem;
    width: 98%;
    text-align: center;

}

.custom-textarea {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-family: Arial, sans-serif;
    resize: none;
    /* Evita que el usuario pueda redimensionar el textarea */
}


.observation {
    justify-content: center;
    margin-left: 1rem;
    margin-right: 1rem;
    gap: 19rem;
    height: 110px;

}

/* Sucursal section */
.factura-section {
    margin-top: .5rem;
    display: grid;
    border-top: 1px solid #e2e8f0;
    padding-top: 1rem;
    gap: 1rem;
    width: 98%;

}

.orden-date1-section>.three-column-grid {
    display: grid;
    grid-template-columns: 1.01fr 1fr 1fr;
    /* Ajusta el tamaño de cada columna */
    gap: 1rem;
}

.orden-date1-section>.two-column-grid {

    gap: 21.1rem;
}


/* Save button */
.generate-button-container-orden {
    display: flex;
    margin-top: 0rem;
    margin-left: 50rem;
    height: 2.4rem;
}

.generate-button-container-factint {
    text-align: end;
    height: 2.4rem;
}

.verorden-button-container-orden {
    display: flex;
    height: 2.5rem;
    margin-left: 55%;
    gap: 2rem;
}

.table-section {
    width: 98%;
}

.mq2 {
    bottom: .5rem;
}

.form-date-basic---orden>.table-section.up {
    margin-top: -1rem;
}

/* Para mitad de pantalla en laptops y tablets en vertical */
@media (min-width: 630px) and (max-width: 768px) {

    /* Estilos para mitad de pantalla LAPTOP hasta TABLET VERTICAL */

    .form-header-ordencompra {
        margin-left: .8rem;
        width: 32rem;
        gap: .1rem;
    }

    .form-title-ordencompra {
        font-size: 1.2rem;
    }

    .generate-button-container-orden {
        margin-left: 30%;
        margin-top: -2px;
    }

    .table-section-mq {
        margin-top: 9rem;
    }

    .mq2 {
        left: 25%;
    }

    .orden-date1-section {
        gap: .4rem;
    }

    .orden-date1-section>.three-column-grid {
        display: grid;
        grid-template-columns: 1fr;
        /* Ajusta el tamaño de cada columna */
        gap: 0.4rem;
    }

    .factura-section {
        gap: .4rem;
    }

    .verorden-button-container-orden {
        height: 2rem;
        margin-left: 7%;
        gap: 2rem;
    }

    .form-date-basic---orden>.table-section {
        margin-top: 9rem;
    }

    .form-date-basic---orden>.table-section.up {
        margin-top: 4rem;
    }

    .form-date-basic---orden>.factura-section>.two-column-grid.sp {
        gap: 2rem;
    }

}

/* Para mitad de pantalla en monitores externos nuevos y tablets en horizontal */
@media (min-width: 950px) and (max-width: 1199px) {
    .form-date-basic---orden {
        height: 36rem;
    }

    .generate-button-container-orden {
        margin-left: 25%;
    }

    .mq2 {
        left: 14%;
    }


    .orden-date1-section>.two-column-grid {
        gap: 3rem;
    }

    .factura-section>.two-column-grid {
        gap: 3rem;
    }


}

/* Estilos fijos para laptops entre 1200px y hasta cualquier ancho superior */
/* Media query para anchos a partir de 1200px */
@media (min-width: 1200px) {}



@media (min-width: 1500px) {
    .form-date-basic---orden {
        height: 39rem;
    }

    .generate-button-container-orden {
        margin-left: 85%;
    }
}

/* Para monitores completamente grandes  - monitor nuevo 1920px*/
@media (min-width: 1820px) {

    /* Estilos para monitores externos de gran tamaño */
    .form-date-basic---orden {
        width: 112%;

        height: 46rem;

    }

    .form-header-ordencompra {
        margin-top: -1rem;
        margin-bottom: 0.8rem;
        margin-left: 7rem;

    }

    .generate-button-container-orden {
        margin-left: 90%;

    }
}