/* Main container */
.creation-form-container {
    position: fixed;/* 
    background-color: rgba(235, 248, 255, 0.6); *//* propuesta johan gil */
    background-color: rgba(241, 239, 230, .8);/* propuesta mafe */
    padding-bottom: 20px;
    border-radius: 59px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
    max-width: 100%;
    max-height: 100vh;
    justify-content: center;
    z-index: 2;

}

/* Header */
.form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .8rem 3rem 1.3rem 6%;
}

.form-title {
    font-size: 1.6rem;
    font-weight: 600;
    color: #2d3748;
    margin-right: 2.8rem;
}



/* Back button */
.back-button {
    color: #181818a0 !important;
    width: 42px !important;
    height: 30px !important;
    border-radius: 12px !important;
    /* background-color: #5da6fa !important; *//* propuesta johan gil */
    background-color: #FCB10B !important;/* propuesta Mafe */
    text-align: center !important;
    padding: 4px !important;
    box-shadow: 1px 2px 5px 1px rgba(72, 72, 72, 0.3) !important;
}


.back-button:hover {
    color: #3182ce !important;
}

/* Form container */
.form-content {
    margin-top: -8px;
    position: fixed;
    display: flex;
    flex-direction: column;
    max-width: 108%;
}

.form-date-basic {
    /* border-top: 1px solid #b6bbc2; *//* propuesta johan gil */
    height: 26.2rem;
    border-bottom: 1px solid #b6bbc2;
    overflow-y: auto;
}

/* Formulario Persona Natural */
.personal-natural-section {
    position: relative;
    display: grid;
    gap: .396rem;
    width: 98%;

}

.personal-natural-section>.three-column-grid {
    display: grid;
    grid-template-columns: 1.01fr 1fr 1fr;
    /* Ajusta el tamaño de cada columna */
    gap: 1rem;
}

/* Formulario Persona Juridica */
.personal-juridica-section {
    position: relative;
    display: grid;
    gap: .396rem;
    padding-bottom: 2.90rem;
    width: 98%;

}

/* Modificacion de clases especificas = Selector Descendientes Padre Hijo */
.personal-juridica-section>.three-column-grid {
    display: grid;
    grid-template-columns: 1.01fr 1fr 1fr;
    /* Ajusta el tamaño de cada columna */
    gap: 1rem;
}

.proveedor-container {
    display: flex;
    flex-direction: column;
    position: relative;
    /* border-top: 1px solid #a4a8ad; *//* propuesta johan gil */
    width: 98%;
    /* height: 25.98rem; */
    margin-top: 0.9rem;
    padding-top: .1rem;
}

.proveedor-section {
    display: grid;
    gap: .2rem;
    text-align: center;
    padding-bottom: .4rem;
}


.proveedor-section>.person-type {
    margin-left: 5rem;
    gap: 2rem;
}

.proveedor-container>.icon-arrowdown-aso {
    position: absolute;
    top: 0%;
    /* Ajusta la posición vertical */
    right: 95%;
    /* Ajusta la posición horizontal */
    transform: rotate(180deg);
    /* Aplica una rotación de 180 grados */
}

/* Person type section */
.person-type {
    display: flex;
    margin-left: 25%;
    padding-bottom: 1rem;
    gap: 8rem;
}

.person-type-label {
    color: #4a5568;
}

.checkbox-label {
    display: inline-flex;
    align-items: center;
}

.sucursal-section>.icon-arrowdown-aso {
    position: absolute;
    top: 60%;
    /* Ajusta la posición vertical */
    left: 50%;
    /* Ajusta la posición horizontal */
}

.form-checkbox {
    appearance: none;
    width: 1rem;
    height: 1rem;
    border: 1px solid #1d1e20;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
}

.form-checkbox:checked {
    background-color: #4299e1;
    border-color: #4299e1;
}

.checkbox-text {
    margin-left: 0.5rem;
}

/* Grid layouts */
.two-column-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: auto;
    gap: 20rem;
}

.two-column-grid>* {
    height: 20px;
}

/* Estructura general del grid para pantallas grandes */
.three-column-grid {
    display: grid;
    grid-template-columns: 1fr .9fr 1fr;
    grid-template-areas:
        "item-1 item-2 item-3";
}

/* Define cada item en el grid */
.item-1 {
    grid-area: item-1;
}

.item-2 {
    grid-area: item-2;
}

.item-3 {
    grid-area: item-3;
}

.four-column-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* Cinco columnas con igual ancho */
    grid-template-areas:
        "item-1 item-2 item-3 item-4";
    /* Define las áreas de la cuadrícula */

    gap: 2rem;
}
.cuentas-bancarias-section {
    margin-top: 1%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
.five-column-grid-modifi {
    margin-top: .1%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr .1fr;
    /* Cinco columnas con igual ancho */
    grid-template-areas:
        "item-1 item-2 item-3 item-4 item-5";
    /* Define las áreas de la cuadrícula */

    gap: 2rem;
}

.five-column-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    /* Cinco columnas con igual ancho */
    grid-template-areas:
        "item-1 item-2 item-3 item-4 item-5";
    /* Define las áreas de la cuadrícula */

    gap: 2rem;
}


.six-column-grid {
    display: grid;
    grid-template-columns:.1fr 1fr 1fr 1fr 1fr 1fr;
    /* Cinco columnas con igual ancho */
    grid-template-areas:
        "item-1 item-2 item-3 item-4 item-5 item-6";
    /* Define las áreas de la cuadrícula */

    gap: 2rem;
}


/* Oculta el segundo teléfono si tiene la clase .invisible */
.phone-input-containervis.invisible {
    display: none;
    opacity: 0;
}


.responsive-textf-tel {
    max-width: 85%;
    /*  right: 0.8rem; */
}


/* Sucursal section */
.sucursal-section {
    display: grid;
    border-top: 1px solid #e2e8f0;
    padding-top: 0.01rem;
    text-align: center;
    gap: 9rem;
    width: 98%;
}

.sucursal-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 1rem;
}

/* Phone input */
.phone-input-container {
    position: relative;
    display: flex;
}

.add-phone-button-container {
    position: absolute;
    right: 0;
    display: flex;
}

.add-phone-button {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border: none;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    background-color: #4299e1;
}

.add-phone-button:hover {
    background-color: #3182ce;
}

.add-phone-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

/* Checkbox container */
.bottom-checkboxes {
    display: flex;
    align-items: center;
    gap: 8rem;
    margin: 1rem 0 0 16%;
}

/* Save button */
.save-button-container {
    display: flex;
    margin-top: -2.1rem;
    margin-left: 48rem;
}
.save-button-container2-clean {
    display: flex;
    margin-top: -2.5rem;
}

.save-button {
    border-radius: 20px !important;
    background-color: #0098FF !important;
    gap: .4rem;
}

.save-button:hover {
    background-color: #23a2ad;
}

/* Phone input visibility */
.phone-input-containervis {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.phone-input-containervis.visible {
    opacity: 1;
}

/* Styles for FormControl */
.input-form-control {
    min-width: 70px;
    min-height: 40px;
    height: 40px;
    width: 100%;
    background-color: white;
    border-radius: 12px !important;
}

/* InputLabel */
.input-label {
    font-size: 0.9rem !important;
    top: -6px !important;
}

.input-label.MuiInputLabel-shrink {
    font-size: 1rem !important;

    top: 0 !important;
}



/* Select general */
.input-select {
    border-radius: 12px !important;
    height: 40px !important;
}



/* Styles for TextField */
.input-textfield {
    height: 40px;
    background-color: white;
    border-radius: 10px;
    padding: 0;
    width: 100%;
}


.input-textfield .MuiOutlinedInput-root {
    height: 100%;
    border-radius: 10px;
}

.input-textfield .MuiInputLabel-root {
    font-size: 0.9rem;
    top: -6px !important;
}

.input-textfield .MuiInputLabel-shrink {
    font-size: 1rem;
    top: 0 !important;
}

/* Styles tableadjuntos.js */
.icon-check {
    margin-left: 8px;
    color: green;
}

.icon-error {
    margin-left: 8px;
    color: red;
}




/* Media Queries */

/* Para celulares en posición vertical */
@media (max-width: 390px) {
    /* Estilos específicos para celulares VERTICAL */


    .move-up {
        grid-area: item-2;
        /* Ocupa la posición del teléfono 2 en pantalla pequeña */
    }

    .creation-form-container {
        border-radius: 20px;
        width: 20.3rem;
        height: 49rem;
        margin-left: -1rem;
    }

    .form-date-basic {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 40.2rem;
        width: 19rem;
    }



    .form-title {
        text-align: center;
        font-size: 15.5px;
        width: 100%;
    }

    .form-header {
        margin-left: 1rem;
        width: 19.7rem;
    }

    .person-type {
        margin-left: 2rem;
        gap: 1rem;
    }

    .form-checkbox,
    .checkbox-text {
        margin-left: 0;
        margin-right: 0.2rem;
    }

    .form-content {
        position: relative;
        left: 1.1rem;
        width: 18rem;
        gap: 0.4rem;
    }

    .sucursal-section {
        border-top: solid 1px #8e929a69;
        flex-direction: row;
        margin-top: .3rem;
        padding-bottom: 0.5rem;
        gap: 0.4rem;
    }

    .sucursal-title {
        font-size: 20px;
    }

    .two-column-grid {
        grid-template-columns: 1fr;
        gap: 0.4rem;
        padding-bottom: 0rem;
    }

    .three-column-grid {
        grid-template-columns: 1fr;
        grid-template-areas:
            "item-1"
            "item-3"
            "item-2";
        gap: 0.4rem;
        /* Mueve la posición de item-2 hacia abajo */
    }

    .personal-juridica-section {
        top: 1rem;
        padding-bottom: 1.8rem;

    }

    .personal-juridica-section>.three-column-grid {
        display: grid;
        grid-template-columns: 1fr;
        /* Ajusta el tamaño de cada columna */
        gap: 0.4rem;
    }

    .personal-natural-section>.three-column-grid {
        display: grid;
        grid-template-columns: 1fr;
        /* Ajusta el tamaño de cada columna */
        gap: 0.4rem;
    }

    /* Reemplaza item-2 por el contenido de bottom-checkboxes y save-button-container cuando .invisible esté activa */
    .phone-input-containervis.invisible+.bottom-checkboxes {
        grid-area: item-2;
        margin-top: 0;
        /* Ajuste opcional para el espacio superior */
    }

    .phone-input-containervis.invisible+.bottom-checkboxes+.save-button-container {
        grid-area: item-2;
        margin-top: 0;
        /* Ajuste opcional para el espacio superior */
    }

    .item-1 {
        grid-area: item-1;
    }

    .item-2 {
        grid-area: item-2;
    }

    .item-3 {
        grid-area: item-3;
    }

    .bottom-checkboxes {
        margin-left: 0.1rem;
        gap: 1rem;
        margin-top: 0.1rem;
    }

    .responsive-textfield-right,
    .responsive-textfield-left,
    .responsive-textfield-left-Direc {
        width: 100%;
        left: 0;
    }

    .responsive-textfield-left-Direc {
        max-width: 100%;
    }

    .responsive-textf-tel {
        width: 84.8%;
        left: 0.1rem;
    }

    .responsive-txetf-tel2 {
        width: 100%;
    }

    .form-email {
        margin-top: .1rem;
    }

    .save-button-container {
        display: flex;
        bottom: 1px;
        margin-top: .5rem;
        margin-left: 0rem;
    }


    .proveedor-container {
        top: -10px;
        position: relative;
        max-width: 20.3rem;
        /* height: 45rem; */
    }

    .proveedor-section {
        gap: 25px;
    }

    .proveedor-section>.person-type {
        flex-direction: column;
        gap: 4px;
        margin: 0;
    }

    .proveedor-section>.three-column-grid {
        margin-top: -17px;
        gap: 5px;
    }

    .proveedor-section>.two-column-grid {
        margin-top: -5px;
        gap: 5px;
    }

    .sucursal-section>.icon-arrowdown-aso {
        top: 38rem;
        /* Ajusta la posición vertical */
    }


    .proveedor-container>.icon-arrowdown-aso {
        top: 15%;
        left: 84%;
        /* Ajusta la posición horizontal */
    }

    .button-container-veri {
        gap: 2rem;
    }

    .save-button {
        width: 100%;
    }

    .edit-button {
        width: 100%;
    }


}

/* Para celulares en posición horizontal */
@media (max-width: 844px) and (max-height: 390px) {

    /* Estilos específicos para celulares en posición horizontal */
    .creation-form-container {
        margin-left: auto;
        width: 89%;
        height: 360px;
    }

    .form-date-basic {
        overflow-y: scroll;
        height: 15rem;
    }

    .form-content {
        position: relative;
        width: 93%;
        margin-left: 2rem;
    }

    .form-header {
        margin-top: 1%;
        margin-left: 8%;
        width: 85%;
        text-align: center;
    }

    .form-title {
        width: 80%;
        font-size: 18px;
        text-align: center;
    }

    .person-type {
        margin-left: 7rem;
    }

    .personal-juridica-section {
        padding: 0;
    }



    .sucursal-section>.three-column-grid {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;

    }

    .responsive-textf-tel {
        max-width: 84%;

    }


    .sucursal-section {
        margin-top: 0.5rem;
        gap: 0.18rem;
    }

    .responsive-textf-tel {
        left: -9px;
        width: 85%;
    }

    .save-button {
        width: 50%;
    }

    .bottom-checkboxes {
        margin-top: 10px;
        margin-left: 2rem;
    }

    .save-button-container {
        width: 80%;
        margin-top: 4px;
        margin-left: 28%;
    }

    .proveedor-section>.person-type {
        gap: 14px;
        margin: 0;
    }

    .container-verify {
        height: 24rem;
        width: 29rem;
    }

    .sucursal-section>.icon-arrowdown-aso {
        top: 12rem;
        /* Ajusta la posición vertical */
    }



}

/* Para mitad de pantalla en laptops y tablets en vertical */
@media (min-width: 630px) and (max-width: 768px) {
    /* Estilos para mitad de pantalla LAPTOP hasta TABLET VERTICAL */

    /* Estilos específicos para celulares VERTICAL */
    .move-up {
        grid-area: item-2;
        /* Ocupa la posición del teléfono 2 en pantalla pequeña */
    }

    .creation-form-container {
        justify-content: center;
        border-radius: 50px;
        width: 34rem;
        max-height: 33rem;
        margin-left: auto;
    }

    .form-date-basic {
        overflow-y: scroll;
    }

    .form-title {
        text-align: center;
        font-size: 20px;
        width: 100%;
    }

    .form-header {
        margin-left: .8rem;
        width: 32rem;
        gap: .1rem;
    }

    .person-type {
        margin-left: 6rem;
        gap: 4rem;
    }

    .form-checkbox,
    .checkbox-text {
        margin-left: 0;
        margin-right: 0.2rem;
    }

    .form-content {
        position: relative;
        left: 1.9rem;
        width: 30rem;
        gap: 0.4rem;
    }

    .sucursal-section {
        border-top: solid 1px #8e929a69;
        border-bottom: solid 1px #8e929a69;
        flex-direction: row;
        margin-top: .8rem;
        padding-bottom: 1rem;
        gap: 0.4rem;
    }

    .sucursal-title {
        margin-top: .5rem;
        font-size: 20px;
    }

    .two-column-grid {
        grid-template-columns: 1fr;
        gap: 0.4rem;
        padding-bottom: 0rem;
    }

    .three-column-grid {
        grid-template-columns: 1fr;
        grid-template-areas:
            "item-1"
            "item-3"
            "item-2";
        gap: 0.4rem;
        /* Mueve la posición de item-2 hacia abajo */
    }

    .personal-juridica-section>.three-column-grid {
        display: grid;
        grid-template-columns: 1fr;
        /* Ajusta el tamaño de cada columna */
        gap: 0.4rem;
    }

    .personal-natural-section>.three-column-grid {
        display: grid;
        grid-template-columns: 1fr;
        /* Ajusta el tamaño de cada columna */
        gap: 0.4rem;
    }



    /* Reemplaza item-2 por el contenido de bottom-checkboxes y save-button-container cuando .invisible esté activa */
    .phone-input-containervis.invisible+.bottom-checkboxes {
        grid-area: item-2;
        margin-top: 0;
        /* Ajuste opcional para el espacio superior */
    }

    .phone-input-containervis.invisible+.bottom-checkboxes+.save-button-container {
        grid-area: item-2;
        margin-top: 0;
        /* Ajuste opcional para el espacio superior */
    }

    .item-1 {
        grid-area: item-1;
    }

    .item-2 {
        grid-area: item-2;
    }

    .item-3 {
        grid-area: item-3;
    }

    .bottom-checkboxes {
        margin-left: 0.1rem;
        gap: 1rem;
        margin-top: 0.1rem;
    }


    .bottom-checkboxes {
        justify-content: center;
        gap: 4rem;
    }

    .save-button {
        width: 50%;
    }

    .save-button-container {
        display: flex;
        margin-top: -5px;
        margin-left: 30%;
    }

    .sucursal-section>.icon-arrowdown-aso {
        top: 22rem;
        /* Ajusta la posición vertical */
    }

    .proveedor-container {
        border: none;
        top: -1rem;
        height: auto;
    }

    .proveedor-container>.icon-arrowdown-aso {
        position: absolute;
        z-index: 1;
        top: 45%;
        left: 84%;
        /* Ajusta la posición horizontal */
    }

    .proveedor-section>.person-type {
        flex-direction: column;
        gap: .2rem;
    }

    .proveedor-section>.three-column-grid {
        gap: .2rem;
    }

}

/* Para mitad de pantalla en monitores externos nuevos y tablets en horizontal */
@media (min-width: 950px) and (max-width: 1199px) {

    /* Estilos para pantalla de 960px a 1024px de ancho (tablet horizontal) */
    .creation-form-container {
        margin-left: auto;
        width: 89%;
        min-height: 700px;
    }

    .form-date-basic {
        margin-top: 1rem;
        height: 28rem;
    }

    .person-type {
        padding-bottom: 2rem;
    }

    .form-header {
        margin-top: 2.5%;
        margin-left: 10%;
        width: 80%;
        text-align: center;
    }

    .form-title {
        width: 70%;
        text-align: center;
    }

    .form-content {
        width: 79%;
        gap: 0.8rem;
        min-height: 600px;
        margin-left: 2.9rem;
    }

    .sucursal-section {
        margin-top: 0.5rem;
        gap: 0.8rem;
    }

    .sucursal-section>.three-column-grid {
        gap: 3rem;
    }

    .responsive-textf-tel {
        width: 80%;
    }

    .save-button {
        width: 70%;
    }

    .save-button-container {
        margin-top: 2rem;
        margin-left: 30%;
    }

    .personal-natural-section {
        gap: .6rem;
    }

    .personal-juridica-section {
        gap: .6rem;
    }

    .sucursal-section>.icon-arrowdown-aso {
        top: 70%;
    }

    .proveedor-container {
        height: auto;
    }

    .proveedor-section>.person-type {
        justify-content: center;
        margin-top: 1rem;
        margin-left: 1rem;
        gap: 1rem;
    }

    .proveedor-section {
        gap: .5rem;
    }

    .container-verify {
        width: 80%;
    }

    .button-container-veri {
        gap: 2rem;
    }

    .container-verify>.button-container-veri>.save-button {
        width: 100%;
    }

    .edit-button {
        width: 100%;
    }
}

/* Estilos fijos para laptops entre 1200px y hasta cualquier ancho superior */
/* Media query para anchos a partir de 1200px */
@media (min-width: 1200px) {
    .creation-form-container {
        margin-left: 3rem;
        width: 83%;
        min-height: 530px;
        overflow: hidden;
    }



    .form-title {
        width: 70%;
        text-align: center;
    }

    .form-content {
        width: 72%;
        gap: 0.25rem;
        min-height: 600px;
        left: 13rem;
    }

    .sucursal-section {
        margin-top: .7rem;
        gap: 0.4rem;
    }

    .three-column-grid {
        gap: 2rem;
    }
}



@media (min-width: 1500px) {
    .creation-form-container {
        left: auto;
        right: auto;
        min-height: 730px;
        overflow: hidden;
    }


    .form-title {
        width: 70%;
    }

    .form-content {
        max-height: auto;
    }

    .personal-natural-section {
        gap: 1.8rem;

    }

    .form-date-basic {
        height: 36rem;
    }

    .sucursal-section {
        gap: 1.8rem;
        padding-bottom: 2rem;
    }

    .personal-juridica-section {
        margin-top: 2rem;
        gap: 1.8rem;
        top: .1rem;

    }

    .bottom-checkboxes {
        margin-left: 30%;
    }

    .save-button-container {
        margin-left: auto;
    }

    .sucursal-section>.icon-arrowdown-aso {
        top: 80%;
    }

    .two-column-grid {
        gap: 23rem;
    }

    .proveedor-container {
        /* height: 35.9rem; */
    }

    .proveedor-section {
        gap: 2rem;
    }

    .proveedor-section>.person-type {
        justify-content: center;
    }

    .table-container {
        height: 15.5rem;
    }


}

/* Para monitores completamente grandes  - monitor nuevo 1920px*/
@media (min-width: 1820px) {
    /* Estilos para monitores externos de gran tamaño */

    .creation-form-container {
        padding: 3rem 8rem;
        width: 80%;
        right: 10rem;
        height: 54rem;
        border-radius: 80px;
        overflow: auto;
    }

    .form-header {
        margin-top: -1rem;
        margin-bottom: 0.8rem;
        margin-left: 7rem;
    }

    .form-content {
        width: 65%;
        left: auto;
    }

    .form-date-basic {
        margin-top: 1rem;
        height: 43rem;
    }

    .sucursal-section {
        margin-top: 2.29rem;
        margin-bottom: 0.5rem;
        gap: 1.8rem;
    }

    .sucursal-title {
        margin-top: 2rem;
    }


    .save-button-container {
        margin-left: 90%;
    }

    .person-type {
        margin-left: 30%;
        margin-top: 3%;
        margin-bottom: 1%;
    }

    .bottom-checkboxes {
        margin-left: 27.5%;
    }

    .personal-juridica-section {
        margin-top: 2rem;
        padding-bottom: 1.8rem;
        gap: 2rem;

    }

    .proveedor-container {
        border-top: 1px solid #e2e8f0;
        gap: 1rem;
       /*  height: 40.8rem; */
    }

    .proveedor-section {
        gap: 2rem;
    }

    .proveedor-section>.sucursal-title {
        margin: 0;
        gap: 0;
    }

    .proveedor-section>.person-type {
        justify-content: center;
        margin: 0;
        gap: 6rem;
    }

    .table-container {
        height: 18.9rem;
    }

}