
body,
html {
  height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background: linear-gradient(to right, #eaf3fa, #67b3ff);
  overflow: hidden; /* Agrega esta línea para ocultar el scroll */
}

.p, h1, h2, h3{
  font-family: Arial, Helvetica, sans-serif;
}

.logo-container {
  position: fixed; /* Fijo en la ventana */
  top: -9px; /* Ajusta según sea necesario */
  right: -149px; /* Ajusta según sea necesario */
  width: 220px; /* Ajusta el tamaño de la imagen */
  height: 90px; /* Ajusta el tamaño de la imagen */
 /*  background-image: url('../styles/icons/Logo_Pelicano_v2_Der.png'); */
  background-image: url('../logoOfi/Logo_Font_HTML.png');  /*Ruta de la imagen */
  background-size: contain; /* Mantiene la proporción */
  background-repeat: no-repeat; /* No repetir la imagen */
  z-index: 0; /* Asegúrate de que esté encima de otros elementos */
}

.logo-container2 {
  position: fixed; /* Fijo en la ventana */
  bottom: -9px; /* Ajusta según sea necesario */
  right: -7rem; /* Ajusta según sea necesario */
  width: 260px; /* Ajusta el tamaño de la imagen */
  /* height: 64px; */ /* Ajusta el tamaño de la imagen */
  height: 57px; /* Ajusta el tamaño de la imagen */
 /*  background-image: url('../styles/icons/Logo_Pelicano_v2_Der.png'); */
  background-image: url('../logoOfi/Logo_HTML_Pasar.png');  /*Ruta de la imagen */
  background-size: contain; /* Mantiene la proporción */
  background-repeat: no-repeat; /* No repetir la imagen */
  z-index: 0; /* Asegúrate de que esté encima de otros elementos */
}


.login-root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;/* 
  background: linear-gradient(135deg, #e6f3ff 0%, #2062a5 100%); *//* Propuesta Johan Gil */
  background: #F1EFE6;/* Propuesta Mafe */
  position: relative; /* Asegura que los elementos absolutamente posicionados se basen en este contenedor */
  overflow: hidden; /* Evita que se muestre el scroll */
  z-index: 1;
}


.login-container {
  position: relative;
  background: white;
  border-radius: 55px;
  box-shadow: 12px 16px 20px rgba(0, 0, 0, 0.4);
  width: 50%; /* Ancho predeterminado 600px */
  height: 380px; /* Alto predeterminado 400px*/
  display: flex; 
}

.login-form {
  margin-top: 30px;
  margin-bottom: 20px;
  flex: 0 0 55%; /* Cambiar a ocupar el 55% */
  text-align: center;
  z-index: 1;
}

.login-form h2 {
  margin: 0 0 30px;
  color: #333;
  font-size: 32px;
}
p.firma {
  font-size: 1rem; /* Ajusta según necesidad */
  font-weight: 600; /* Un grosor intermedio */
  color: #334B99; /* Color oscuro pero suave */
  text-align: center; /* Centrado */
  margin-top: 10px;
}

.input-group {
  margin-bottom: 20px;
  text-align: center;
}

.input-group input {
  width: 60%;
  padding: 10px;
  color: #000000;
  border: 1px solid #e0e0e0;
  background-color: rgba(0, 0, 0, 0.2); /* Negro con 30% de opacidad */
  border-radius: 14px;
  font-size: 14px;
}

.input-group input::placeholder {
  color: #ffffff;
  text-align: center;
}

.forgot-password {
  text-align: center;
  font-size: 12px;
  color: #999;
  margin-bottom: 20px;
}

.forgot-password a {
  color: #999;
  text-decoration: none;
}

.login-button {
  background: #4a7bff;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 14px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: .7rem;
}

.login-button:hover {
  background: #3a6ae8;
}

.welcome-section h1 {
  margin: 2rem 0 15rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
  font-size: 35px;
}

.image-container {
  /* width: 295px; 
  height: 295px; tamaño original del cubo*/ 
  width: 450px; 
  height: 450px; 
  /*background: url('icons/Logo_Pelicano_v2_Blanco.png') no-repeat center;  Asegúrate de que la imagen esté centrada  CUBO NO ELIMINAR*/
  background: url('../logoOfi/Logo_Login_P-Oficial.png') no-repeat center; /* Asegúrate de que la imagen esté centrada */
  background-size: cover; /* Usa 'cover' para llenar el contenedor */
  /* margin-top: -230px; espaciado del logo original del cubo*/
  margin-top: -260px;
}


.error-alert {
  text-align: center; /* Centra el texto */
  margin: 10px 70px; /* Espaciado vertical */
  margin-bottom: 0%;
}

.welcome-section {
  position: absolute; 
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(90deg, #557DFF 20%, #334B99 100%);
  color: white;
  font-size: 15px;
  font-weight: bold;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  height: 100%;
  width: 100%; /* Cambia a 45% para que ocupe el espacio deseado */
  z-index: 2; 
  right: 0; /* Alinea la sección a la derecha */
}



@media(max-width: 390px) {
  .input-group input {
    width: 60%; /* Aumentar el ancho del input */
  }
  
  .login-button {
    font-size: 14px; /* Ajustar tamaño del botón */
  }

  .login-form {
    flex: 0 0 100%; /* Cambiar a ocupar el 100% */
  }

  .welcome-section.hide-welcome {
    width: 0; /* Ocultar la sección al 0% */
    opacity: 0; /* Hacer que la opacidad sea 0 */
    transition: width 1s ease, opacity 2.5s ease; /* Transición suave */
  }

  .image-container {
    width: 150px; /* Ajustar el ancho del logo */
    height: 150px; /* Ajustar la altura del logo */
    margin-top: -180px; /* Espacio entre el texto y el logo */
  }

  .login-root::after {
    content: '';
    display: block;
    position: absolute;
    top: 100px; /* Ajusta según sea necesario */
    left: 50%;
    transform: translateX(-50%) translateY(20px); /* Desplazado hacia arriba inicialmente */
    opacity: 0; /* Comienza invisible */
    width: 250px; /* Ajusta el tamaño */
    height: 250px; /* Ajusta la altura según necesites */
    background-image: url('../logoOfi/Logo_ICON_Window.png');
    /*background-image: url('./icons/Logo_Pelicano_v2_Der_Negro.png');  Ruta de la imagen */
    background-size: contain; /* Mantiene la proporción */
    background-repeat: no-repeat; /* Evita que se repita */
    animation: fadeInUp 0.5s ease 2.5s forwards; /* Animación con delay */
  }

  @keyframes fadeInUp {
    0% {
      transform: translateX(-50%) translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  }
}


/* Media Queries para Responsividad */
@media (max-width: 1636px) {
  .login-container{
    width: 50%; /* Ancho predeterminado */
    height: 100%; /* Alto predeterminado */
  }
  .input-group input {
    width: 60%; /* Aumentar el ancho del input */
  }
  .login-button {
    font-size: 14px; /* Ajustar tamaño del botón */
  }
  .welcome-section h1 {
    font-size: 24px; /* Ajustar el tamaño de la fuente en pantallas pequeñas */
  }
  .image-container {
    width: 340px; 
    height: 340px;/* SE DIEMENSIONA PARA QUE EN PANTALLAS DE LAPTOPS GENEREN RESPONSIVE */
    margin-top: -265px;  /* Se hace este ajuste específico para el NUEVO LOGO */
  }
}

@media (max-width: 1490px) {
  .login-container{
    width: 60%; /* Ancho predeterminado */
    height: 100%; /* Alto predeterminado */
  }
  .input-group input {
    width: 60%; /* Aumentar el ancho del input */
  }
  .login-button {
    font-size: 14px; /* Ajustar tamaño del botón */
  }
  .welcome-section h1 { 
    font-size: 24px; /* Ajustar el tamaño de la fuente en pantallas pequeñas */
  }
}

@media (max-width: 1040px) {
  .login-container{
    width: 70%; /* Ancho predeterminado */
    height: 100%; /* Alto predeterminado */
  }
  .input-group input {
    width: 60%; /* Aumentar el ancho del input */
  }
  .login-button {
    font-size: 14px; /* Ajustar tamaño del botón */
  }
  .welcome-section h1 {
    font-size: 24px; /* Ajustar el tamaño de la fuente en pantallas pequeñas */
  }
}


@media (max-width: 844px) {
  .login-container {
    width: 80%; /* Ajustar al 100% en pantallas más pequeñas */
    height: 100%; /* Permitir que la altura se ajuste */
  }
  .login-form h2 {
    font-size: 28px; /* Ajustar el tamaño de fuente */
  }

}

@media (max-width: 768px) and (orientation: portrait) {
  .login-container {
    width: 90%; /* Ajustar al 90% en orientación retrato */
  }
  .login-form h2 {
    font-size: 30px; /* Ajustar el tamaño de fuente */
  }
}


