/* Estilos para el Paper */
.styled-paper {
  padding: 12px;
  background-color: #ffffff;
  border-radius: 15px !important; 
  width: 100%;
  height: 100%; /* Asegúrate de que el Paper ocupe todo el contenedor */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Evita que el Paper se desborde */
}

@media (min-width: 600px) {
  .styled-paper {
    max-width: 400px;
  }
}

/* Estilos para la lista */
.styled-list {
  padding: 0;
  flex: 1; /* Permite que ocupe el espacio restante */
  overflow-y: scroll; /* Asegura el desplazamiento vertical */
  max-height: 400px; /* Ajusta el tamaño según lo que desees para la lista */
  
  /* Ocultar scrollbar visualmente */
  scrollbar-width: none; /* Oculta el scrollbar en Firefox */
}

.styled-list::-webkit-scrollbar {
  display: none; /* Oculta el scrollbar en navegadores basados en WebKit */
}

/* Estilos para los elementos de la lista */
.styled-list-item {
  margin-bottom: 8px;
  padding: 8px;
  transition: background-color 0.3s ease;
  border-radius: 4px; /* Añade bordes redondeados */
}

.styled-list-item.read {
  background-color: #f5f5f5; /* Notificaciones leídas con un gris claro */
}

.styled-list-item.unread {
  background-color: #1976d2; /* Notificaciones no leídas con azul claro */
  color: white; /* Asegura que el texto sea visible */
}

/* Añadir esta sección para manejar el color del texto */
.styled-list-item.unread .MuiListItemText-primary {
  color: white; /* Color del texto principal */
}

.styled-list-item.unread .MuiListItemText-secondary {
  color: #bbdefb; /* Color del texto secundario */
}

.styled-list-item:hover.read {
  background-color: #eeeeee; /* Hover en notificación leída */
}

.styled-list-item:hover.unread {
  background-color: #1565c0; /* Hover en notificación no leída */
}

/* Estilos para el botón */
.show-more-button {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

/* Estilos para el scrollbar si decides volver a mostrarlo */
.styled-list::-webkit-scrollbar-thumb {
  background-color: #1976d2; /* Azul en la barra de desplazamiento */
  border-radius: 4px;
}



/* Estilos Responsivos */
@media only screen and (max-width: 390px) {
 
}

@media (min-width: 630px) and (max-width: 768px) {

}

@media (min-width: 950px) and (max-width: 1199px) {
}

@media (min-width: 1500px) {
}

@media (min-width: 1820px) {

  .styled-paper {
      padding: 1rem;
    }
    .styled-list {
      max-height: 100%; /* Ajusta el tamaño según lo que desees para la lista */
    }
}